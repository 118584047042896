.modal__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;

  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    padding-bottom: .5em !important;
    padding-top: .5em !important;

    p {
      margin: 0 !important;
    }
  }
}