.levelFilter-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 1em;
  width: 100%;
}
.levelFilter-wrapper .levelFilter-sub-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.levelFilter-wrapper .levelFilter-sub-wrapper .levelFilter-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.levelFilter-wrapper .levelFilter-sub-wrapper .levelFilter-header .levelFilter-type {
  color: #AAA;
  font-size: 0.9em;
  font-style: italic;
}
.levelFilter-wrapper .levelFilter-sub-wrapper .levelFilter-header .levelFilter-subheader {
  display: flex;
  gap: 1em;
}
.levelFilter-wrapper .levelFilter-sub-wrapper .levelFilter-header .levelFilter-subheader .levelFilter-clear {
  color: #FF2277;
  cursor: pointer;
  font-size: 0.9em;
  font-style: italic;
}
.levelFilter-wrapper .levelFilter-sub-wrapper .levelFilter-header .levelFilter-subheader .levelFilter-clear:hover {
  color: #FF0033;
}
.levelFilter-wrapper .levelFilter-sub-wrapper .levelFilter-input {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
}
.levelFilter-wrapper .levelFilter-sub-wrapper .levelFilter-input input::placeholder {
  font-size: 1em !important;
}
.levelFilter-wrapper .levelFilter-sub-wrapper .levelFilter-input .ant-input-number {
  width: 100%;
}
.levelFilter-wrapper .levelFilter-sub-wrapper .levelFilter-input .ant-select {
  width: 100%;
}