.benchmark-search-modal .ant-modal-close-x svg {
  color: #21416F;
}
.benchmark-search-modal .ant-modal-content .ant-modal-body {
  padding: 0 20px;
}
.benchmark-search-modal .ant-modal-content .ant-modal-header {
  padding: 20px 20px 4px;
}
.benchmark-search-modal .ant-modal-content .ant-modal-title {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
}

.benchmark-chart-modal .ant-modal-content {
  padding: 0;
}
.benchmark-chart-modal .ant-modal-content .ant-modal-title {
  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
}
.benchmark-chart-modal .ant-modal-content .ant-modal-header {
  padding: 20px 20px 4px;
}
.benchmark-chart-modal .ant-modal-content .ant-modal-body {
  padding: 0 20px;
}
.benchmark-chart-modal .ant-modal-close-x svg {
  color: #21416F;
}

.benchmark-filter-popover-columns-content {
  width: 200px;
}
.benchmark-filter-popover-columns-content .benchmark-filter-popover-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 10px;
}
.benchmark-filter-popover-columns-content .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.benchmark-filter-popover-columns-content .ant-checkbox-wrapper span {
  font-size: 14px;
  line-height: 37px;
}
.benchmark-filter-popover-columns-content .ant-checkbox-group-item {
  margin-right: 0;
}
.benchmark-filter-popover-columns-content .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.benchmark-filter-popover-columns-content .ant-checkbox-checked .ant-checkbox-inner {
  background: #134176;
  border: 1px solid #134176;
  border-radius: 3px;
}

.benchmark-filter-popover-groups-content {
  padding: 10px 40px 22px 12px;
}
.benchmark-filter-popover-groups-content .wrapper {
  display: flex;
}
.benchmark-filter-popover-groups-content .wrapper .column:last-child {
  border-left: 1px solid #D8D8D8;
  padding-left: 50px;
}
.benchmark-filter-popover-groups-content .wrapper .column .action {
  background: transparent;
  border: 1px solid #21416F;
  border-radius: 24px;
  color: #21416F;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  margin: 2em auto 0;
  outline: none;
  padding: 10px 30px;
  transition: 0.3s all;
}
.benchmark-filter-popover-groups-content .wrapper .column .action:hover {
  background: #21416F;
  color: #fff;
}
.benchmark-filter-popover-groups-content .wrapper .column .apply {
  background: #21416F;
  color: #fff;
}
.benchmark-filter-popover-groups-content .wrapper .column .apply:disabled {
  background: #A0A7B7;
  cursor: not-allowed;
}
.benchmark-filter-popover-groups-content .wrapper .column .apply:hover {
  opacity: 0.5;
}
.benchmark-filter-popover-groups-content .wrapper .column .groups {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 1em;
  width: 300px;
}
.benchmark-filter-popover-groups-content .wrapper .column .groups .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.benchmark-filter-popover-groups-content .wrapper .column .groups .group {
  align-items: center;
  border-bottom: 1px solid #D8D8D8;
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
  padding-bottom: 18px;
}
.benchmark-filter-popover-groups-content .wrapper .column .groups .group .actions {
  align-items: center;
  display: flex;
}
.benchmark-filter-popover-groups-content .wrapper .column .groups .group .actions .delete {
  height: 14px;
  margin-left: 10px;
  position: relative;
  transition: 0.4s all;
  width: 14px;
}
.benchmark-filter-popover-groups-content .wrapper .column .groups .group .actions .delete:after {
  background: #C82922;
  border: 1px solid #C82922;
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  transform: rotate(45deg);
  width: 100%;
}
.benchmark-filter-popover-groups-content .wrapper .column .groups .group .actions .delete:before {
  background: #C82922;
  border: 1px solid #C82922;
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 100%;
}
.benchmark-filter-popover-groups-content .wrapper .column .groups .group .actions .delete:hover {
  opacity: 0.4;
}
.benchmark-filter-popover-groups-content .wrapper .column .title {
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 20px;
}

.benchmark-filter-popover-organizations-content .organizations-content-wrapper {
  display: flex;
}
.benchmark-filter-popover-organizations-content .organizations-content-wrapper .organizations-content-column {
  min-width: 300px;
}
.benchmark-filter-popover-organizations-content .organizations-content-wrapper .organizations-content-column:last-child {
  border-left: 1px solid #D8D8D8;
  padding-left: 50px;
}
.benchmark-filter-popover-organizations-content .organizations-content-wrapper .organizations-content-column .organizations-content-action {
  background: #21416F;
  border: 1px solid #21416F;
  border-radius: 24px;
  color: #fff;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  margin: 25px auto 0;
  outline: none;
  padding: 10px 30px;
  transition: 0.3s all;
}
.benchmark-filter-popover-organizations-content .organizations-content-wrapper .organizations-content-column .organizations-content-action:hover {
  opacity: 0.5;
}
.benchmark-filter-popover-organizations-content .organizations-content-wrapper .organizations-content-column .organizations-content-filters {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 1em;
}
.benchmark-filter-popover-organizations-content .organizations-content-wrapper .organizations-content-column .organizations-content-organizations {
  max-height: 300px;
  overflow-y: auto;
}
.benchmark-filter-popover-organizations-content .organizations-content-wrapper .organizations-content-column .organizations-content-organizations .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.benchmark-filter-popover-organizations-content .organizations-content-wrapper .organizations-content-column .organizations-content-organizations .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.benchmark-filter-popover-organizations-content .organizations-content-wrapper .organizations-content-column .organizations-content-organizations .ant-checkbox-wrapper {
  align-items: center;
  color: #000;
  display: flex;
  font-size: 16px;
  line-height: 30px;
}
.benchmark-filter-popover-organizations-content .organizations-content-wrapper .organizations-content-column .organizations-content-organizations .ant-checkbox-wrapper span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.benchmark-filter-popover-organizations-content .organizations-content-wrapper .organizations-content-column .organizations-content-organizations .ant-checkbox-wrapper .ant-checkbox {
  overflow: visible;
}
.benchmark-filter-popover-organizations-content .organizations-content-wrapper .organizations-content-column .organizations-content-organizations .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #21416F;
  border-color: #21416F;
}
.benchmark-filter-popover-organizations-content .organizations-content-wrapper .organizations-content-column .organizations-content-subtitle {
  color: #000000;
  font-size: 12px;
  font-style: italic;
  line-height: 18px;
  margin-bottom: 14px;
}
.benchmark-filter-popover-organizations-content .organizations-content-wrapper .organizations-content-column .organizations-content-title {
  color: #000;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 4px;
}

.benchmark-filter-popover-views-content .views-content-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}
.benchmark-filter-popover-views-content .views-content-wrapper .views-content-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
}
.benchmark-filter-popover-views-content .views-content-wrapper .views-content-header .views-content-new {
  background: #21416F;
}
.benchmark-filter-popover-views-content .views-content-wrapper .views-content-header .views-content-title {
  color: #000;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 4px;
}
.benchmark-filter-popover-views-content .views-content-wrapper .views-content-list {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  min-height: 200px;
  overflow-y: auto;
  width: 100%;
}
.benchmark-filter-popover-views-content .views-content-wrapper .views-content-list .views-content-empty {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
.benchmark-filter-popover-views-content .views-content-wrapper .views-content-list .views-content-list-item {
  align-items: center;
  border-bottom: 1px solid #DDD;
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0 0.25rem 0.5rem;
  width: 100%;
}
.benchmark-filter-popover-views-content .views-content-wrapper .views-content-list .views-content-list-item:hover {
  background-color: #DDD;
  cursor: pointer;
}
.benchmark-filter-popover-views-content .views-content-wrapper .views-content-list .views-content-list-item:last-child {
  border-bottom: none;
}
.benchmark-filter-popover-views-content .views-content-wrapper .views-content-list .views-content-list-item .views-content-list-item-title {
  margin: 0;
}
.benchmark-filter-popover-views-content .views-content-wrapper .views-content-list .views-content-list-item .views-content-list-item-action .views-content-delete {
  color: red;
}
.benchmark-filter-popover-views-content .views-content-wrapper .views-content-list .views-content-list-item-selected {
  background-color: #21416F;
  color: #FFF;
}
.benchmark-filter-popover-views-content .views-content-wrapper .views-content-list .views-content-list-item-selected .views-content-edit {
  color: #FFF;
}

.benchmark-wrapper .benchmark-header {
  align-items: center;
  border-bottom: 1px solid #DDDDDD;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1em 0;
}
.benchmark-wrapper .benchmark-header .benchmark-header-items {
  align-items: center;
  display: flex;
}
.benchmark-wrapper .benchmark-header .benchmark-header-items .benchmark-header-select {
  margin-right: 48px;
}
.benchmark-wrapper .benchmark-header .benchmark-header-items .benchmark-header-select:last-child {
  margin-right: 0;
}
.benchmark-wrapper .benchmark-header .benchmark-header-items .benchmark-header-select p {
  color: #7A8295;
  font-size: 14px;
  font-style: italic;
  line-height: 23px;
  margin: 0;
}
.benchmark-wrapper .benchmark-header .benchmark-header-items .benchmark-header-select .ant-select .ant-select-selector {
  height: 100%;
  min-width: 200px;
  padding-left: 0;
}
.benchmark-wrapper .benchmark-header .benchmark-header-items .benchmark-header-select .ant-select .ant-select-selection-item {
  color: #134176;
  font-size: 18px;
  font-weight: normal;
  line-height: 23px;
}
.benchmark-wrapper .benchmark-header .benchmark-header-items .benchmark-header-select .ant-select .ant-select-arrow {
  color: #21416F;
  font-size: 12px;
  top: 50%;
}
.benchmark-wrapper .benchmark-header .benchmark-header-items .benchmark-header-item {
  margin-right: 14px;
}
.benchmark-wrapper .benchmark-header .benchmark-header-items .benchmark-header-item .benchmark-searchbar {
  padding: 10px 26px 10px 12px;
}
.benchmark-wrapper .benchmark-header .benchmark-header-items .benchmark-header-item .benchmark-searchbar input {
  color: #1E3968;
  font-size: 14px;
  line-height: 23px;
  margin-right: 60px;
  opacity: 0.5;
}
.benchmark-wrapper .benchmark-header .benchmark-header-items .benchmark-header-item .benchmark-searchbar input::placeholder {
  color: #1E3968;
  font-size: 14px;
  line-height: 23px;
}
.benchmark-wrapper .benchmark-filters {
  display: flex;
  justify-content: space-between;
  padding: 1em 0;
}
.benchmark-wrapper .benchmark-filters .benchmark-filtergroup {
  align-items: center;
  display: flex;
}
.benchmark-wrapper .benchmark-filters .benchmark-filtergroup .benchmark-compare-text {
  color: #7A8295;
  font-size: 14px;
  font-style: italic;
  line-height: 23px;
  margin-right: 20px;
}
.benchmark-wrapper .benchmark-filters .benchmark-filtergroup .benchmark-filter {
  margin-right: 24px;
}
.benchmark-wrapper .benchmark-filters .benchmark-filtergroup .benchmark-filter-reverse {
  margin-left: 24px;
}
.benchmark-wrapper .benchmark-filters .benchmark-filtergroup .benchmark-filter .benchmark-favorites-reset,
.benchmark-wrapper .benchmark-filters .benchmark-filtergroup .benchmark-filter-reverse .benchmark-favorites-reset {
  color: #134176;
}
.benchmark-wrapper .benchmark-filters .benchmark-filtergroup .benchmark-filter .benchmark-filter-popover,
.benchmark-wrapper .benchmark-filters .benchmark-filtergroup .benchmark-filter-reverse .benchmark-filter-popover {
  align-items: center;
  border: 2px solid #A0A7B7;
  border-radius: 4px;
  color: #134176;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  line-height: 23px;
  padding: 11px 12px;
}
.benchmark-wrapper .benchmark-filters .benchmark-filtergroup .benchmark-filter .benchmark-filter-popover p,
.benchmark-wrapper .benchmark-filters .benchmark-filtergroup .benchmark-filter-reverse .benchmark-filter-popover p {
  margin: 0;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.benchmark-wrapper .benchmark-filters .benchmark-filtergroup .benchmark-filter .benchmark-filter-popover svg,
.benchmark-wrapper .benchmark-filters .benchmark-filtergroup .benchmark-filter-reverse .benchmark-filter-popover svg {
  color: #21416F;
  margin-left: 50px;
}
.benchmark-wrapper .benchmark-content {
  margin-top: 2rem;
  width: 100%;
}
.benchmark-wrapper .benchmark-content .benchmark-step {
  padding-top: 22px;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-header {
  align-items: center;
  background-color: #FFF;
  box-shadow: 0 1px #DDE0E6;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-header:last-child {
  border-right: 1px solid #D8D8D8;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-header .benchmark-grid-header-columns {
  border-left: 1px solid #D8D8D8;
  color: #000000;
  font-size: 12px;
  line-height: 15px;
  overflow: hidden;
  padding: 6px 0 16px 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-header .benchmark-grid-header-columns:first-child {
  border-left: none;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-header .benchmark-grid-header-options {
  cursor: pointer;
  position: absolute;
  right: 1rem;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-content {
  display: flex;
  color: #21416f;
  flex-direction: column;
  min-height: 100px;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-content .benchmark-grid-row .benchmark-grid-row-header {
  background-color: #DDE0E6;
  display: flex;
  justify-content: space-between;
  padding: 0.5em 1em;
  width: 100%;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-content .benchmark-grid-row .benchmark-grid-row-header .benchmark-grid-row-header-title {
  font-size: 16px;
  font-weight: 500;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-content .benchmark-grid-row .benchmark-grid-row-header .benchmark-grid-row-header-actions {
  display: flex;
  gap: 1em;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-content .benchmark-grid-row .benchmark-grid-row-header .benchmark-grid-row-header-actions .benchmark-grid-row-header-chart,
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-content .benchmark-grid-row .benchmark-grid-row-header .benchmark-grid-row-header-actions .benchmark-grid-row-header-map {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
  margin-right: 17px;
  transition: 0.3s all;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-content .benchmark-grid-row .benchmark-grid-row-header .benchmark-grid-row-header-actions .benchmark-grid-row-header-chart:hover,
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-content .benchmark-grid-row .benchmark-grid-row-header .benchmark-grid-row-header-actions .benchmark-grid-row-header-map:hover {
  opacity: 0.5;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-content .benchmark-grid-row .benchmark-grid-row-header .benchmark-grid-row-header-actions .benchmark-grid-row-header-chart .benchmark-grid-row-header-map-image,
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-content .benchmark-grid-row .benchmark-grid-row-header .benchmark-grid-row-header-actions .benchmark-grid-row-header-map .benchmark-grid-row-header-map-image {
  margin-right: 4px;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-content .benchmark-grid-row .benchmark-grid-row-header .benchmark-grid-row-header-actions .benchmark-grid-row-header-info {
  align-items: center;
  color: #7A8295;
  display: flex;
  font-size: 12px;
  line-height: 20px;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-content .benchmark-grid-row .benchmark-grid-row-header .benchmark-grid-row-header-actions .benchmark-grid-row-header-info svg {
  margin-left: 4px;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-content .benchmark-grid-row .benchmark-grid-row-content {
  display: flex;
  min-height: 2.5em;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-content .benchmark-grid-row .benchmark-grid-row-content .benchmark-grid-row-column {
  border-left: 1px solid #D8D8D8;
  color: #000;
  font-size: 14px;
  line-height: 23px;
  overflow: hidden;
  padding: 0.5em 1em;
  white-space: nowrap;
  width: 100%;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-content .benchmark-grid-row .benchmark-grid-row-content .benchmark-grid-row-column:first-child {
  border-left: none;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-content .benchmark-grid-row .benchmark-grid-row-content .benchmark-grid-row-column .ant-checkbox-inner::after {
  border-color: #FFF;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-footer {
  display: flex;
  gap: 2em;
  justify-content: flex-end;
  margin: 30px 0;
  width: 100%;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-footer .benchmark-grid-download {
  background: #134176;
  border-color: #134176;
}
.benchmark-wrapper .benchmark-content .benchmark-grid .benchmark-grid-footer .benchmark-grid-download:disabled {
  background: #DDD;
  border-color: #DDD;
  cursor: not-allowed;
}