.benchmark-chart-wrapper {
  display: flex;
  flex-direction: column;

  .benchmark-chart-header {
    align-items: center;
    display: flex;
    padding-bottom: 26px;

    .benchmark-chart-indicator {
      color: #7A8295;
      font-size: 12px;
    }
  }

  .benchmark-chart-content {
    display: flex;
    flex-direction: column;

    .benchmark-chart-content-header {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .benchmark-chart-header-filters {
        align-items: center;
        display: flex;
        gap: 2rem;

        .benchmark-chart-filter {
          align-items: center;
          display: flex;
          gap: 1rem;

          .benchmark-chart-filter-title {
            font-size: 14px;
            margin: 0 10px 0 0;
          }
        }
      }

      .benchmark-chart-header-info {
        align-items: center;
        display: flex;
        gap: 2rem;

        .benchmark-chart-header-info-group {
          display: flex;
          flex-direction: column;

          .text {
            color: #000;
            font-size: 12px;
            margin: 0;
          }

          .value {
            color: #000;
            font-size: 18px;
            font-weight: 600;
            margin: 0;
          }
        }
      }
    }

    .benchmark-chart-content-footer {
      display: flex;
      flex-direction: column;
      gap: .5rem;
      margin-top: 20px;

      .benchmark-chart-content-description {
        margin: 0 0 2rem;
      }

      .benchmark-chart-content-label {
        font-size: 1.125rem;
        font-weight: 500;
        margin: 0;
      }
    }
  }
}