.view-editor {
  display: flex;
  flex-direction: column;
  width: 100%;

  .view-editor-columns {
    display: flex;
    justify-content: space-between;

    .view-editor-column {
      border-right: 1px solid #EFEFEF;
      padding: 0 15px;
      width: calc(100% / 3);

      &:last-child {
        border-right: none;
      }

      .view-editor-column-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;

        .view-editor-elements {
          display: flex;
          flex-direction: column;
          height: 600px;
          overflow-y: auto;
          width: 100%;

          .view-editor-element {
            align-items: center;
            border-bottom: 1px solid #DDD;
            display: flex;
            gap: 1rem;
            padding: .5rem 0;
            width: 100%;

            &:last-child {
              border-bottom: none;
            }

            .view-editor-element-checkbox {
              align-items: center;
              display: flex;
              justify-content: center;
            }

            .view-editor-element-info {
              display: flex;
              flex-direction: column;
              max-width: 80%;

              p {
                margin: 0;
              }

              .view-editor-element-title {
                font-weight: 600;
              }

              .view-editor-element-type {
                font-size: 0.8rem;
                font-style: italic;
              }
            }
          }

          .view-editor-category {
            font-size: 1.5rem;       
            font-weight: 600;
            width: 100%;

            p {
              margin: .25rem 0 0;
            }
          }

          .view-editor-subcategory {
            font-size: 1.2rem;
          }
        }

        .view-editor-field {
          display: flex;
          flex-direction: column;

          .view-editor-checkbox-group {
            border: 1px solid #DDD;
            border-radius: 2px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            max-height: 150px;
            overflow-y: auto;
            padding: 0 .5rem;
            width: 100%;

            label {
              font-weight: 400;
            }
          }
        }
      }

      .view-editor-column-title {
        color: #134176;
        font-size: 18px;
        margin: 0 0 1rem;
      }
    }
  }

  .view-editor-footer {
    align-items: center;
    display: flex;
    gap: 1rem;
    justify-content: flex-end;
    margin-top: 2rem;
    width: 100%;

    .view-editor-save {
      align-items: center;
      background: #134176;
      display: flex;
      gap: 1rem;

      p {
        margin: 0;
      }
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #134176;
  border-color: #134176;

  &:hover {
    background-color: #134176;
    border-color: #134176;
  }
}