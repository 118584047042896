.group-editor {
  display: flex;
  flex-direction: column;
  width: 100%;

  .group-editor-columns {
    display: flex;
    justify-content: space-between;

    .group-editor-column {
      border-right: 1px solid #EFEFEF;
      padding: 0 15px;
      width: 25%;

      &:last-child {
        border-right: none;
      }

      .ant-input {
        &::placeholder {
          font-size: 16px;
          padding-left: 3px;
        }
      }

      .ant-select {
        color: #6c757d;
        width: 85%;
      }

      .group-editor-action {
        background: none;
        border: 1px solid #21416F;
        border-radius: 25px;
        color: #21416F;
        margin-top: 15px;
        max-width: 300px;
        padding: 10px 0;
        text-align: center;
        transition: .3s all;
        width: 100%;
        
        &:hover {
          background: #144176;
          color: #fff;
        }
      }

      .group-editor-action-clear {
        color: #C82922;
        cursor: pointer;
        display: block;
        font-size: 14px;
        line-height: 18px;
        margin-top: 14px;
      }

      .group-editor-action-delete {
        cursor: pointer;
        height: 14px;
        margin-right: 10px;
        position: relative;
        transition: .4s all;
        width: 14px;

        &:after {
          background: #C82922;
          border: 1px solid #C82922;
          content: '';
          display: block;
          position: absolute;
          top: 6px;
          transform: rotate(45deg);
          width: 100%;
        }
      
        &:before {
          background: #C82922;
          border: 1px solid #C82922;
          content: '';
          display: block;
          position: absolute;
          top: 6px;
          transform: rotate(-45deg);
          width: 100%;
        }

        &:hover {
          opacity: .4;
        }
      }

      .group-editor-active-list {
        margin: 15px 0 0;
        max-height: 560px;
        overflow-y: auto;
        padding: 0;
  
        li {
          align-items: center;
          display: flex;
          font-size: 16px;
          list-style: none;
          margin-bottom: 10px;
        }
      }

      .group-editor-column-subtitle {
        color: #6c757d;
        font-style: italic;
        margin: 0;
      }

      .group-editor-column-title {
        color: #134176;
        font-size: 18px;
        margin: 0;

        span {
          font-weight: bold;
        }
      }

      .group-editor-column-content {
        margin-top: 40px;

        .ant-input-affix-wrapper, .ant-input-wrapper input {
          border: none;
  
          &:focus, &:hover, &:active {
            border: none;
            box-shadow: none;
          }
        }
  
        .ant-input-search {
          margin-top: 40px;
        } 
  
        .ant-input-search-button {
          align-items: center;
          border: none;
          display: flex;
          justify-content: center;
        }
  
        .group-editor-description {
          color: #134176;
          margin-bottom: 25px;
        }

        .group-editor-name-input {
          color: #AAA;
          padding: 10px 0 10px 5px;
    
          &::placeholder {
            color: #AAA;
            font-size: 16px;
          }
        }
      }

      .group-editor-input {
        border: 1px solid #D9D9D9;
        border-radius: 2px;
        width: 100%;
  
        input {
          color: #AAA;
  
          &::placeholder {
            color: #AAA;
          }
        }
  
        svg {
          color: #21416F;
        }
      }

      .group-editor-selection-list {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        max-height: 480px;
        min-height: 400px;
        overflow-y: auto;
        
        .ant-checkbox + span {
          font-size: 16px;
        }
  
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #134176;
          border-color: #134176;
        }
  
        .ant-checkbox-wrapper {
          margin: 0 0 10px;
        }
      }

      .group-editor-switch {
        align-items: center;
        display: flex;
        margin-top: 14px;
      
        .ant-switch {
          margin-right: 10px;
        }

        .ant-switch-checked {
          background: #21416F;
        }
      }
    }

    .group-editor-comparison {
      background: #FEFEDC;
      border-left: 1px solid #EFEFEF;
      overflow: hidden;
    }
  }

  .group-editor-modal-actions-wrapper {
    bottom: 75px;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 10px;

    button {
      background: #134176;
      border: 1px solid #21416F;
      border-radius: 25px;
      color: #fff;
      font-size: 14px;
      line-height: 18px;
      padding: 8px 22px;
      transition: .3s all;
  
      &:disabled {
        cursor: not-allowed;
        opacity: .4;
      }

      &:hover {
        opacity: .4;
      }
    }

    .button__cancel {
      background: transparent;
      border: none;
      color: #C82922;
      font-size: 14px;
      line-height: 18px;
  
      &:hover {
        color: #8b0a02;
        opacity: 1;
      }
    }
  }
}
