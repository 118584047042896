.segmentation-filter-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.segmentation-filter-wrapper .segmentation-filter-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.segmentation-filter-wrapper .segmentation-filter-header .segmentationfilters-filter-type {
  color: #AAA;
  font-size: 0.9em;
  font-style: italic;
}
.segmentation-filter-wrapper .segmentation-filter-header .segmentationfilters-filter-subheader {
  display: flex;
  gap: 1em;
}
.segmentation-filter-wrapper .segmentation-filter-header .segmentationfilters-filter-subheader .segmentationfilters-filter-clear {
  color: #FF2277;
  cursor: pointer;
  font-size: 0.9em;
  font-style: italic;
}
.segmentation-filter-wrapper .segmentation-filter-header .segmentationfilters-filter-subheader .segmentationfilters-filter-clear:hover {
  color: #FF0033;
}
.segmentation-filter-wrapper .segmentation-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
}
.segmentation-filter-wrapper .segmentation-input-wrapper input::placeholder {
  font-size: 1em !important;
}
.segmentation-filter-wrapper .segmentation-input-wrapper .ant-input-number {
  width: 100%;
}
.segmentation-filter-wrapper .segmentation-input-wrapper .ant-select {
  width: 100%;
}