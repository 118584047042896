.modal__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.modal__wrapper .ant-table-tbody > tr > td,
.modal__wrapper .ant-table-thead > tr > th {
  padding-bottom: 0.5em !important;
  padding-top: 0.5em !important;
}
.modal__wrapper .ant-table-tbody > tr > td p,
.modal__wrapper .ant-table-thead > tr > th p {
  margin: 0 !important;
}