.mainWrapper {
  background-color: #fff;
  border: solid 1px #ddd;
  border-radius: 5px;
  margin: 20px 0;
  padding: 1rem;

  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .extendedTags {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;

    .extendedTag {
      background-color: #21416f;
      border-radius: 20px;
      color: #fff;
      font-size: .8rem;
      padding: .1em 1em;
    }
  }

  .footerWrapper {
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 1px solid #ddd;
    display: flex;
    height: 65px;
    justify-content: space-between;
    margin: 20px -20px -20px -20px;
    padding: 0 20px;
    width: calc(100% + 40px);
  }

  .header {
    align-items: center;
    background: #eee;
    display: flex;
    font-weight: bold;
    justify-content: space-between;
    padding: 10px;

    p {
      text-align: center;
      width: 33%;
    }
  }

  .indicator {
    align-items: center;
    color: #7a8295;
    display: flex;
    font-family: "Open Sans";
    font-size: 14px;
    justify-content: space-between;
    line-height: 1.43;
    margin-bottom: 11px;

    .containerButton {
      color: #21416f;
      display: flex;
      font-size: 14px;
      line-height: 23px;

      .comments {
        cursor: pointer;
      }

      .info {
        cursor: pointer;
      }
    }

    .containerTags {
      display: flex;

      .tagItem {
        background-color: #ebedf1;
        border-radius: 5px;
        margin-right: 10px;
        padding: 5px 10px;
      }
    }

    svg {
      cursor: pointer;
      height: 40px;
      transition: .3s all;
      width: 40px;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .multi__organizations {
    display: flex;
    justify-content: center;
    padding: 20px 0;  
    
    .content {
      text-align: center;
      width: 33%;
    }

    .status {
      text-align: center;
      width: 33%;
    }

    .title {
      text-align: center;
      width: 33%;
    }
  }
}