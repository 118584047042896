.benchmark-chart-wrapper {
  display: flex;
  flex-direction: column;
}
.benchmark-chart-wrapper .benchmark-chart-header {
  align-items: center;
  display: flex;
  padding-bottom: 26px;
}
.benchmark-chart-wrapper .benchmark-chart-header .benchmark-chart-indicator {
  color: #7A8295;
  font-size: 12px;
}
.benchmark-chart-wrapper .benchmark-chart-content {
  display: flex;
  flex-direction: column;
}
.benchmark-chart-wrapper .benchmark-chart-content .benchmark-chart-content-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.benchmark-chart-wrapper .benchmark-chart-content .benchmark-chart-content-header .benchmark-chart-header-filters {
  align-items: center;
  display: flex;
  gap: 2rem;
}
.benchmark-chart-wrapper .benchmark-chart-content .benchmark-chart-content-header .benchmark-chart-header-filters .benchmark-chart-filter {
  align-items: center;
  display: flex;
  gap: 1rem;
}
.benchmark-chart-wrapper .benchmark-chart-content .benchmark-chart-content-header .benchmark-chart-header-filters .benchmark-chart-filter .benchmark-chart-filter-title {
  font-size: 14px;
  margin: 0 10px 0 0;
}
.benchmark-chart-wrapper .benchmark-chart-content .benchmark-chart-content-header .benchmark-chart-header-info {
  align-items: center;
  display: flex;
  gap: 2rem;
}
.benchmark-chart-wrapper .benchmark-chart-content .benchmark-chart-content-header .benchmark-chart-header-info .benchmark-chart-header-info-group {
  display: flex;
  flex-direction: column;
}
.benchmark-chart-wrapper .benchmark-chart-content .benchmark-chart-content-header .benchmark-chart-header-info .benchmark-chart-header-info-group .text {
  color: #000;
  font-size: 12px;
  margin: 0;
}
.benchmark-chart-wrapper .benchmark-chart-content .benchmark-chart-content-header .benchmark-chart-header-info .benchmark-chart-header-info-group .value {
  color: #000;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
.benchmark-chart-wrapper .benchmark-chart-content .benchmark-chart-content-footer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 20px;
}
.benchmark-chart-wrapper .benchmark-chart-content .benchmark-chart-content-footer .benchmark-chart-content-description {
  margin: 0 0 2rem;
}
.benchmark-chart-wrapper .benchmark-chart-content .benchmark-chart-content-footer .benchmark-chart-content-label {
  font-size: 1.125rem;
  font-weight: 500;
  margin: 0;
}