.level-filter {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 1em;
  width: 100%;

  .level-filter-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .level-filter-header {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .level-filter-title {
        color: #AAA;
        font-size: .9em;
        font-style: italic;
      }

      .level-filter-actions {
        display: flex;
        gap: 1em;

        .level-filter-clear {
          color: #FF2277;
          cursor: pointer;
          font-size: .9em;
          font-style: italic;

          &:hover {
            color: #FF0033;
          }
        }
      }
    }

    .level-filter-content {
      display: flex;
      flex-direction: column;
      gap: .5em;
      width: 100%;
  
      input::placeholder {
        font-size: 1em !important;
      }
  
      .ant-input-number {
        width: 100%;
      }
    
      .ant-select {
        width: 100%;
      }
    }
  }
}