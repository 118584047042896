.mainWrapper {
  background-color: #fff;
  border: solid 1px #ddd;
  border-radius: 5px;
  margin: 20px 0;
  padding: 1rem;
}
.mainWrapper .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}
.mainWrapper .extendedTags {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  gap: 1rem;
}
.mainWrapper .extendedTags .extendedTag {
  background-color: #21416f;
  border-radius: 20px;
  color: #fff;
  font-size: 0.8rem;
  padding: 0.1em 1em;
}
.mainWrapper .footerWrapper {
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid #ddd;
  display: flex;
  height: 65px;
  justify-content: space-between;
  margin: 20px -20px -20px -20px;
  padding: 0 20px;
  width: calc(100% + 40px);
}
.mainWrapper .header {
  align-items: center;
  background: #eee;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  padding: 10px;
}
.mainWrapper .header p {
  text-align: center;
  width: 33%;
}
.mainWrapper .indicator {
  align-items: center;
  color: #7a8295;
  display: flex;
  font-family: "Open Sans";
  font-size: 14px;
  justify-content: space-between;
  line-height: 1.43;
  margin-bottom: 11px;
}
.mainWrapper .indicator .containerButton {
  color: #21416f;
  display: flex;
  font-size: 14px;
  line-height: 23px;
}
.mainWrapper .indicator .containerButton .comments {
  cursor: pointer;
}
.mainWrapper .indicator .containerButton .info {
  cursor: pointer;
}
.mainWrapper .indicator .containerTags {
  display: flex;
}
.mainWrapper .indicator .containerTags .tagItem {
  background-color: #ebedf1;
  border-radius: 5px;
  margin-right: 10px;
  padding: 5px 10px;
}
.mainWrapper .indicator svg {
  cursor: pointer;
  height: 40px;
  transition: 0.3s all;
  width: 40px;
}
.mainWrapper .indicator svg:hover {
  transform: scale(1.1);
}
.mainWrapper .multi__organizations {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.mainWrapper .multi__organizations .content {
  text-align: center;
  width: 33%;
}
.mainWrapper .multi__organizations .status {
  text-align: center;
  width: 33%;
}
.mainWrapper .multi__organizations .title {
  text-align: center;
  width: 33%;
}