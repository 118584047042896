.popover__organization {
  padding: 10px 40px 22px 12px;

  .organization__action {
    margin-top: 25px;
  }

  .radio__groups {
    display: flex;
  }

  .radio__group {

    &:first-child {
      margin-right: 72px;
    }

    .radio__title {
      margin-bottom: 14px;
      font-size: 18px;
      line-height: 23px;
    }

    .ant-radio-group {
      max-height: 200px;
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .ant-radio-wrapper {
        margin-bottom: 5px;
      }

      .ant-radio-checked .ant-radio-inner {
        background: #1E3968;
        border-color: #1E3968;

        &::after {
          content: '';
          background-color: #fff;
        }
      }
    }
  }
}

.popover__group {
  padding: 10px 40px 22px 12px;

  .group__block {
    display: flex;
  }

  .group__radio--item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 18px;
    margin-bottom: 18px;
    border-bottom: 1px solid #D8D8D8;
  }

  .group__item {
    &:first-child {
      border-right: 1px solid #D8D8D8;
    }

    &:last-child {
      padding-left: 50px;
    }

    .group__title {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 23px;
    }

    .group__icons {
      display: flex;
      align-items: center;
    }

    .group__checkboxes {
      max-height: 300px;
      overflow-y: auto;
      width: 300px;
    }

    .ant-radio-group {
      display: flex;
      flex-direction: column;

      .ant-radio-wrapper {
        display: flex;

        > span:not(.ant-radio) {
          display: block;
          width: 100%;
          max-width: 300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        span {
          font-weight: 500;
          line-height: 18px;

          svg {
            color: #1E3968;
          }
        }
      }

      .anticon-edit {
        width: 16px;
        height: 16px;
        transition: .4s all;

        svg {
          width: 16px;
          height: 16px;
          fill: #1E3968;
        }

        &:hover {
          opacity: .4;
        }
      }

      .ant-radio-checked .ant-radio-inner {
        background: #1E3968;
        border-color: #1E3968;

        &::after {
          content: '';
          background-color: #fff;
        }
      }
    }

    .delete-group {
      position: relative;
      width: 14px;
      height: 14px;
      margin-left: 10px;
      transition: .4s all;

      &:hover {
        opacity: .4;
      }

      &:after {
        content: '';
        width: 100%;
        display: block;
        position: absolute;
        transform: rotate(45deg);
        border: 1px solid #C82922;
        background: #C82922;
        top: 6px;
      }

      &:before {
        content: '';
        width: 100%;
        display: block;
        position: absolute;
        transform: rotate(-45deg);
        border: 1px solid #C82922;
        background: #C82922;
        top: 6px;
      }
    }
  }
}

.group__action {
  background: transparent;
  border: 1px solid #21416F;
  border-radius: 24px;
  color: #21416F;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  margin: 50px auto 0;
  outline: none;
  padding: 10px 30px;
  transition: .3s all;

  &:hover {
    background: #21416F;
    color: #fff;
  }
}

.apply__button {
  background: #21416F;
  color: #fff;

  &:hover {
    opacity: .5;
  }
}

.popover__content {
  width: 200px;

  .popover__title {    
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-wrapper {
    span {
      font-size: 14px;
      line-height: 37px;
    }
  }

  .ant-checkbox-group-item {
    margin-right: 0;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: #134176;
    border: 1px solid #134176;
    border-radius: 3px;
  }
}

.popover__individual--group {
  .group__block {
    display: flex;
  }

  .group__item { 
    min-width: 300px;

    &:first-child {
      border-right: 1px solid #D8D8D8;
    }

    &:last-child {
      padding-left: 50px;
    }

    .group__title {
      color: #000;
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 4px;
    }
  
    .group__subtitle {
      color: #000000;
      font-size: 12px;
      font-style: italic;
      line-height: 18px;
      margin-bottom: 14px;
    }

    .group__checkboxes {
      max-height: 300px;
      overflow-y: auto;
  
      .ant-checkbox-group {
        display: flex;
        flex-direction: column;
      }
  
      .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0;
      }
  
      .ant-checkbox-wrapper {
        align-items: center;
        color: #000;
        display: flex;
        font-size: 16px;
        line-height: 30px;
        
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
  
        .ant-checkbox {
          overflow: visible;
        }
  
        .ant-checkbox-checked .ant-checkbox-inner {
          background-color: #21416F;
          border-color: #21416F;
        }
      }
    }

    .action__button {
      background: #21416F;
      border: 1px solid #21416F;
      border-radius: 24px;
      color: #fff;
      display: flex;
      font-size: 14px;
      line-height: 18px;
      margin: 25px auto 0;
      outline: none;
      padding: 10px 30px;
      transition: .3s all;
  
      &:hover {
        opacity: .5;
      }
    }
  }
}

.label {
  font-size: 12px;
  line-height: 23px;
  margin: 0;
}

.individual_organisation_filters {
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
}

.individual_organisation_filters_group_delete {
  cursor: pointer;
  height: 14px;
  margin-right: 10px;
  position: relative;
  transition: .4s all;
  width: 14px;

  &:after {
    background: #C82922;
    border: 1px solid #C82922;
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    transform: rotate(45deg);
    width: 100%;
  }

  &:before {
    background: #C82922;
    border: 1px solid #C82922;
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    transform: rotate(-45deg);
    width: 100%;   
  }

  &:hover {
    opacity: .4;
  }  
}

.individual_organisation_filters_group {
  p {
    margin-bottom: 0;
  }

  .individual_organisation_filters_block {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .ant-input-number {
      width: 100% !important;
    }
  }

  .ant-select {
    width: 100%;

    .ant-select-selector {
      border: 2px solid #A0A7B7;
      border-radius: 4px;
      color: #134176;
      height: 100%;
      padding: 6px 34px 7px 10px;
    }
  }
}

.ant-select {
  color: #6c757d;
  width: 85%;
}

.individual_organisation_filter {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-top: 10px;

  .individual_organisation_filter_add {
    align-items: center;
    background: #21416F;
    border-radius: 50%;
    color: #fff;
    display: flex;
    font-size: 18px;
    height: 22px;
    justify-content: center;
    margin-right: 8px;
    width: 22px;
  }

  .individual_organisation_filter_title {
    color: #21416F;
    font-size: 14px;
    line-height: 18px;
  }
}

.individual_organisation_filter_popover {
  max-height: 350px;
  overflow: auto;

  .individual_organisation_filter_popover_title {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 6px;
  }

  .individual_organisation_filter_popover_list {
    list-style: none;
    margin-bottom: 24px;
    padding: 0;

    li {
      color: #21416F;
      cursor: pointer;
      font-size: 14px;
      line-height: 23px;
      margin-bottom: 5px;
      transition: .3s all;

      &:hover {
        opacity: .4;
      }
    }
  }
}