.mdg-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mdg-wrapper .mdg-title {
  font-size: 1.2rem;
  width: 100%;
}
.mdg-wrapper .mdg-content {
  margin-top: 2rem;
  width: 100%;
}
.mdg-wrapper .mdg-content .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .mdg-tab .mdg-tab-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.mdg-wrapper .mdg-content .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .mdg-tab .mdg-tab-header .mdg-tab-title {
  font-weight: 500;
}
.mdg-wrapper .mdg-content .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .mdg-tab .mdg-tab-content {
  width: 100%;
}
.mdg-wrapper .mdg-content .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .mdg-tab .mdg-tab-content .ant-table-tbody > tr > td,
.mdg-wrapper .mdg-content .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .mdg-tab .mdg-tab-content .ant-table-thead > tr > th {
  padding-bottom: 0.5em !important;
  padding-top: 0.5em !important;
}
.mdg-wrapper .mdg-content .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .mdg-tab .mdg-tab-content .ant-table-tbody > tr > td p,
.mdg-wrapper .mdg-content .ant-tabs .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane .mdg-tab .mdg-tab-content .ant-table-thead > tr > th p {
  margin: 0 !important;
}