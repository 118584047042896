//
// page-title.scss
//

.page-title-box {
    margin: 36.5px 0 38px 0;

    .page-title {
        font-size: 48px;
        margin: 0;
        line-height: 75px;
        color: black;
        font-family: "IBM Plex Sans" !important;
    }
    .page-title-right {
        float: right;
        margin-top: 22px;
    }

    .breadcrumb {
        padding-top: 5px;
    }
}

@include media-breakpoint-down(sm) {
    .page-title-box {
        .page-title {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 70px;
        }
        .breadcrumb {
            display: none;
        }
    }
}

@media (max-width: 640px) {
    .page-title-box {
        .page-title-right {
            display: none;
        }
    }
}

@media (max-width: 419px) {
    .page-title-box .breadcrumb {
        display: none;
    }
}

// --- Questionnaire ---

.containerQuestionnaire {
    padding: 30px 20px;
    border: solid 1px #d8d8d8;

    .ant-table-wrapper {
        border-top: 1px solid #d8d8d8;
    }

    .questionnaireTitle {
        font-size: 24px;
        font-weight: normal;
        line-height: 1.33;
        text-align: left;
        color: #000000;
        margin-bottom: 23.5px;
    }

    .row.mb-2 {
        margin-top: 30px;
        margin-bottom: 0px !important;
    }
}

.containerQuestionnaires {
    padding: 0 20px;
    border: solid 1px #d8d8d8;
}

.containerQuestionnaires tr {
    cursor: pointer !important;
}

.reportsTitle {
    font-size: 24px;
    font-weight: normal;
    line-height: 1.33;
    text-align: left;
    color: #000000;
    margin: 52px 0 21px;
}

.containerSelects {
    display: flex;
    justify-content: space-between;
    margin: 38.5px 0 31px 0;

    .selectRight {
        display: flex;

        .selectQuestionnaires {
            font-family: "Open Sans" !important;
            margin-right: 9px;
        }
    }

    .selectLeft {
        display: flex;

        .selectQuestionnaires {
            margin-right: 9px;

            .ant-select-selection--single {
                border: none;
            }
        }

        .containerButtons {
            display: flex;
            button {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                width: 28px;
                height: 28px;
                border-radius: 5px;
                border: solid 1px #134176;
                color: #134176;

                &:hover {
                    border: solid 1px #134176;
                    color: #134176;
                    opacity: 0.2;
                }
            }

            .activeButton {
                border: solid 1px #134176;
                color: #134176;
                opacity: 0.2;
            }
        }
    }
}

// home page

.homePage_containerHelpCenter {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    margin-bottom: 30px;

    ul {
        padding: 0;
        margin: 0;

        li {
            list-style-type: none;

            a {
                display: flex;
                align-items: center;
                font-size: 16px;
                color: #21416f;
                margin-bottom: 10px;
                i {
                    margin-right: 11px;
                }
            }
        }
    }

    .helpCenter_title {
        font-size: 24px;
        line-height: 1.33;
        color: #000000;
        margin-bottom: 13px;
    }

    .helpCenter_containerLink {
        position: relative;
        padding: 21px 21px 50px 21px;
        min-height: 275px;
        border-radius: 2px;
        border: solid 1px #dddddd;
        background-color: #ffffff;
        width: 100%;
        position: relative;

        button {
            position: absolute;
            bottom: 21px;
            margin: 0;
            padding: 0;
            font-size: 14px;
            line-height: 1.29;
            color: #21416f;
        }
    }

    .helpCenter_right {
        max-width: 490px;
        height: 275px;
        border-radius: 2px;
        border: solid 1px #dddddd;
        background-color: #ffffff;
        width: 100%;
    }
}

// Questionnaires

.ant-collapse-item.ant-collapse-item-active {
    .ant-collapse-header {
        .title {
            color: black !important;
            font-weight: bold;
        }

        .ant-collapse-extra {
            color: #21416f !important;
            font-weight: normal;
        }
    }
}

.ant-collapse-item {
    .ant-collapse-header {
        color: #21416f !important;
        font-size: 18px;
        font-weight: 500;
        .ant-collapse-extra {
            font-size: 14px;
        }
    }

    .ant-collapse-content.ant-collapse-content-active {
        .qs-item {
            .ant-collapse-header {
                font-weight: 500;
                color: #21416f !important;
            }
            // .ant-collapse-item.ant-collapse-item-active {
            //     .ant-collapse-header {
                    
            //         font-weight: 900;
            //         color: #000 !important;
            //     }
            // }
        }
    }
}

// Dashboard

.page-title-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 13px;
    margin-left: 10px;

    .selectLeft {
        display: flex;

        .selectQuestionnaires {
            margin-right: 9px;
        }
    }

    .selectRight {
        .ant-select-selection--single {
            border: none;
        }
    }
}
