.text-wrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.text-wrapper .text-prefix,
.text-wrapper .text-suffix {
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  font-size: 14px;
  justify-content: center;
  height: 32px;
  padding: 0 10px;
  white-space: nowrap;
  width: 85px;
}