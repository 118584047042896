.list__item {
  align-items: center;
  border: 1px solid #D8D8D8;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0.5em 26px;
  transition: 0.3s all;
  width: 100%;
}
.list__item:hover {
  background: #eeeeb3;
}

.list__title {
  color: #1E3968;
  font-size: 22px;
  line-height: 39px;
  margin: 0;
}

.list__actions {
  align-items: center;
  display: flex;
}

.list__button {
  background: #fff;
  border: 1px solid #21416F;
  border-radius: 21px;
  color: #21416F;
  font-size: 13px;
  line-height: 18px;
  opacity: 1;
  padding: 0.25em 1em;
  transition: 0.3s all;
}
.list__button:hover {
  opacity: 0.4;
}