.view-editor {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.view-editor .view-editor-columns {
  display: flex;
  justify-content: space-between;
}
.view-editor .view-editor-columns .view-editor-column {
  border-right: 1px solid #EFEFEF;
  padding: 0 15px;
  width: 33.3333333333%;
}
.view-editor .view-editor-columns .view-editor-column:last-child {
  border-right: none;
}
.view-editor .view-editor-columns .view-editor-column .view-editor-column-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.view-editor .view-editor-columns .view-editor-column .view-editor-column-content .view-editor-elements {
  display: flex;
  flex-direction: column;
  height: 600px;
  overflow-y: auto;
  width: 100%;
}
.view-editor .view-editor-columns .view-editor-column .view-editor-column-content .view-editor-elements .view-editor-element {
  align-items: center;
  border-bottom: 1px solid #DDD;
  display: flex;
  gap: 1rem;
  padding: 0.5rem 0;
  width: 100%;
}
.view-editor .view-editor-columns .view-editor-column .view-editor-column-content .view-editor-elements .view-editor-element:last-child {
  border-bottom: none;
}
.view-editor .view-editor-columns .view-editor-column .view-editor-column-content .view-editor-elements .view-editor-element .view-editor-element-checkbox {
  align-items: center;
  display: flex;
  justify-content: center;
}
.view-editor .view-editor-columns .view-editor-column .view-editor-column-content .view-editor-elements .view-editor-element .view-editor-element-info {
  display: flex;
  flex-direction: column;
  max-width: 80%;
}
.view-editor .view-editor-columns .view-editor-column .view-editor-column-content .view-editor-elements .view-editor-element .view-editor-element-info p {
  margin: 0;
}
.view-editor .view-editor-columns .view-editor-column .view-editor-column-content .view-editor-elements .view-editor-element .view-editor-element-info .view-editor-element-title {
  font-weight: 600;
}
.view-editor .view-editor-columns .view-editor-column .view-editor-column-content .view-editor-elements .view-editor-element .view-editor-element-info .view-editor-element-type {
  font-size: 0.8rem;
  font-style: italic;
}
.view-editor .view-editor-columns .view-editor-column .view-editor-column-content .view-editor-elements .view-editor-category {
  font-size: 1.5rem;
  font-weight: 600;
  width: 100%;
}
.view-editor .view-editor-columns .view-editor-column .view-editor-column-content .view-editor-elements .view-editor-category p {
  margin: 0.25rem 0 0;
}
.view-editor .view-editor-columns .view-editor-column .view-editor-column-content .view-editor-elements .view-editor-subcategory {
  font-size: 1.2rem;
}
.view-editor .view-editor-columns .view-editor-column .view-editor-column-content .view-editor-field {
  display: flex;
  flex-direction: column;
}
.view-editor .view-editor-columns .view-editor-column .view-editor-column-content .view-editor-field .view-editor-checkbox-group {
  border: 1px solid #DDD;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-height: 150px;
  overflow-y: auto;
  padding: 0 0.5rem;
  width: 100%;
}
.view-editor .view-editor-columns .view-editor-column .view-editor-column-content .view-editor-field .view-editor-checkbox-group label {
  font-weight: 400;
}
.view-editor .view-editor-columns .view-editor-column .view-editor-column-title {
  color: #134176;
  font-size: 18px;
  margin: 0 0 1rem;
}
.view-editor .view-editor-footer {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 2rem;
  width: 100%;
}
.view-editor .view-editor-footer .view-editor-save {
  align-items: center;
  background: #134176;
  display: flex;
  gap: 1rem;
}
.view-editor .view-editor-footer .view-editor-save p {
  margin: 0;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #134176;
  border-color: #134176;
}
.ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: #134176;
  border-color: #134176;
}