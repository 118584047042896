@import "../../styles/colours";

.benchmark-search-modal {
  .ant-modal-close-x {
    svg {
      color: #21416F;
    }
  }

  .ant-modal-content  {
    .ant-modal-body {
      padding: 0 20px;
    }

    .ant-modal-header {
      padding: 20px 20px 4px;
    }
    
    .ant-modal-title {
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
    }    
  }
}

.benchmark-chart-modal {
  .ant-modal-content  {
    padding: 0;

    .ant-modal-title {
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
    }

    .ant-modal-header {
      padding: 20px 20px 4px;
    }

    .ant-modal-body {
      padding: 0 20px;
    }
  }

  .ant-modal-close-x {
    svg {
      color: #21416F;
    }
  }
}

.benchmark-filter-popover-columns-content {
  width: 200px;

  .benchmark-filter-popover-title {    
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-wrapper {
    span {
      font-size: 14px;
      line-height: 37px;
    }
  }

  .ant-checkbox-group-item {
    margin-right: 0;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background: #134176;
    border: 1px solid #134176;
    border-radius: 3px;
  }
}

.benchmark-filter-popover-groups-content {
  padding: 10px 40px 22px 12px;

  .wrapper {
    display: flex;

    .column {
      &:last-child {
        border-left: 1px solid #D8D8D8;
        padding-left: 50px;
      }

      .action {
        background: transparent;
        border: 1px solid #21416F;
        border-radius: 24px;
        color: #21416F;
        display: flex;
        font-size: 14px;
        line-height: 18px;
        margin: 2em auto 0;
        outline: none;
        padding: 10px 30px;
        transition: .3s all;
      
        &:hover {
          background: #21416F;
          color: #fff;
        }
      }

      .apply {
        background: #21416F;
        color: #fff;
      
        &:disabled {
          background: #A0A7B7;
          cursor: not-allowed;
        }

        &:hover {
          opacity: .5;
        }
      }

      .groups {
        max-height: 300px;
        overflow-y: auto;
        padding-right: 1em;
        width: 300px;

        .ant-checkbox-group {
          display: flex;
          flex-direction: column;
        }

        .group {
          align-items: center;
          border-bottom: 1px solid #D8D8D8;
          display: flex;
          justify-content: space-between;
          margin-bottom: 18px;
          padding-bottom: 18px;

          .actions {
            align-items: center;
            display: flex;

            .delete {
              height: 14px;
              margin-left: 10px;
              position: relative;
              transition: .4s all;
              width: 14px;
              
              &:after {
                background: #C82922;
                border: 1px solid #C82922;
                content: '';
                display: block;
                position: absolute;
                top: 6px;
                transform: rotate(45deg);
                width: 100%;
              }

              &:before {
                background: #C82922;
                border: 1px solid #C82922;
                content: '';
                display: block;
                position: absolute;
                top: 6px;
                transform: rotate(-45deg);
                width: 100%;
              }

              &:hover {
                opacity: .4;
              }
            }
          }
        }
      }

      .title {
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 20px;
      }      
    }
  }
}

.benchmark-filter-popover-organizations-content {
  .organizations-content-wrapper {
    display: flex;

    .organizations-content-column {
      min-width: 300px;

      &:last-child {
        border-left: 1px solid #D8D8D8;
        padding-left: 50px;
      }

      .organizations-content-action {
        background: #21416F;
        border: 1px solid #21416F;
        border-radius: 24px;
        color: #fff;
        display: flex;
        font-size: 14px;
        line-height: 18px;
        margin: 25px auto 0;
        outline: none;
        padding: 10px 30px;
        transition: .3s all;
    
        &:hover {
          opacity: .5;
        }
      }

      .organizations-content-filters {
        max-height: 300px;
        overflow-y: auto;
        padding-right: 1em;
      }

      .organizations-content-organizations {
        max-height: 300px;
        overflow-y: auto;

        .ant-checkbox-group {
          display: flex;
          flex-direction: column;
        }
    
        .ant-checkbox-wrapper + .ant-checkbox-wrapper {
          margin-left: 0;
        }
    
        .ant-checkbox-wrapper {
          align-items: center;
          color: #000;
          display: flex;
          font-size: 16px;
          line-height: 30px;
          
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
    
          .ant-checkbox {
            overflow: visible;
          }
    
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #21416F;
            border-color: #21416F;
          }
        }
      }

      .organizations-content-subtitle {
        color: #000000;
        font-size: 12px;
        font-style: italic;
        line-height: 18px;
        margin-bottom: 14px;
      }

      .organizations-content-title {
        color: #000;
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 4px;
      }
    }
  }
}

.benchmark-filter-popover-views-content {
  .views-content-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 300px;

    .views-content-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;
      width: 100%;

      .views-content-new {
        background: #21416F;
      }
      
      .views-content-title {
        color: #000;
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 4px;
      }
    }

    .views-content-list {
      display: flex;
      flex-direction: column;
      max-height: 400px;
      min-height: 200px;
      overflow-y: auto;
      width: 100%;

      .views-content-empty {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 100%;
      }

      .views-content-list-item {
        align-items: center;
        border-bottom: 1px solid #DDD;
        display: flex;
        justify-content: space-between;
        padding: .25rem 0 .25rem .5rem;
        width: 100%;

        &:hover {
          background-color: #DDD;
          cursor: pointer;
        }

        &:last-child {
          border-bottom: none;
        }

        .views-content-list-item-title {
          margin: 0;
        }

        .views-content-list-item-action {
          .views-content-delete {
            color: red;
          }
        }
      }

      .views-content-list-item-selected {
        background-color: #21416F;
        color: #FFF;

        .views-content-edit {
          color: #FFF;
        }
      }
    }
  }
}

.benchmark-wrapper {
  .benchmark-header {    
    align-items: center;
    border-bottom: 1px solid #DDDDDD;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1em 0;

    .benchmark-header-items {
      align-items: center;
      display: flex;

      .benchmark-header-select {
        margin-right: 48px;

        &:last-child {
          margin-right: 0;
        }

        p {
          color: #7A8295;
          font-size: 14px;
          font-style: italic;
          line-height: 23px;
          margin: 0;
        }

        .ant-select {
          .ant-select-selector {
            height: 100%;
            min-width: 200px;
            padding-left: 0;
          }

          .ant-select-selection-item {
            color: #134176;
            font-size: 18px;
            font-weight: normal;
            line-height: 23px;
          }

          .ant-select-arrow {
            color: #21416F;
            font-size: 12px;
            top: 50%;
          }
        }        
      }

      .benchmark-header-item {
        margin-right: 14px;

        .benchmark-searchbar {
          padding: 10px 26px 10px 12px;
  
          input {
            color: #1E3968;
            font-size: 14px;
            line-height: 23px;
            margin-right: 60px;
            opacity: .5;
  
            &::placeholder {
              color: #1E3968;
              font-size: 14px;
              line-height: 23px;
            }
          }
        }
      }
    }
  }

  .benchmark-filters {
    display: flex;
    justify-content: space-between;
    padding: 1em 0;

    .benchmark-filtergroup {
      align-items: center;
      display: flex;

      .benchmark-compare-text {
        color: #7A8295;
        font-size: 14px;
        font-style: italic;
        line-height: 23px;
        margin-right: 20px;
      }

      .benchmark-filter {
        margin-right: 24px;
      }

      .benchmark-filter-reverse {
        margin-left: 24px;
      }

      .benchmark-filter,
      .benchmark-filter-reverse {
        .benchmark-favorites-reset {
          color: #134176;
        }

        .benchmark-filter-popover {
          align-items: center;
          border: 2px solid #A0A7B7;
          border-radius: 4px;
          color: #134176;
          cursor: pointer;
          display: flex;
          font-size: 14px;
          line-height: 23px;
          padding: 11px 12px;

          p {
            margin: 0;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          svg {
            color: #21416F;
            margin-left: 50px;
          }
        }
      }
    }
  }

  .benchmark-content {
    margin-top: 2rem;
    width: 100%;

    .benchmark-step {
      padding-top: 22px;
    }

    .benchmark-grid {
      .benchmark-grid-header {
        align-items: center;
        background-color: #FFF;
        box-shadow: 0 1px #DDE0E6;
        display: flex;
        justify-content: space-between;
        position: sticky;
        top: 0;
        z-index: 10;
        
        &:last-child {
          border-right: 1px solid #D8D8D8;
        }

        .benchmark-grid-header-columns {
          border-left: 1px solid #D8D8D8;
          color: #000000;
          font-size: 12px;
          line-height: 15px;
          overflow: hidden;
          padding: 6px 0 16px 12px;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;

          &:first-child {
            border-left: none;
          }
        }

        .benchmark-grid-header-options {
          cursor: pointer;
          position: absolute;
          right: 1rem;
        }
      }

      .benchmark-grid-content {
        display: flex;
        color: #21416f;
        flex-direction: column;
        min-height: 100px;

        .benchmark-grid-row {
          .benchmark-grid-row-header {
            background-color: #DDE0E6;
            
            display: flex;
            justify-content: space-between;
            padding: .5em 1em;
            width: 100%;

            .benchmark-grid-row-header-title {
              font-size: 16px;
              font-weight: 500;
            }

            .benchmark-grid-row-header-actions {
              display: flex;
              gap: 1em;

              .benchmark-grid-row-header-chart,
              .benchmark-grid-row-header-map {
                align-items: center;
                cursor: pointer;
                display: flex;
                gap: .5rem;
                margin-right: 17px;
                transition: .3s all;

                &:hover {
                  opacity: .5;
                }

                .benchmark-grid-row-header-map-image {
                  margin-right: 4px;
                }
              }

              .benchmark-grid-row-header-info {
                align-items: center;
                color: #7A8295;
                display: flex;
                font-size: 12px;
                line-height: 20px;

                svg {
                  margin-left: 4px;
                }
              }
            }
          }

          .benchmark-grid-row-content {
            display: flex;
            min-height: 2.5em;

            .benchmark-grid-row-column {
              border-left: 1px solid #D8D8D8;
              color: #000;
              font-size: 14px;
              line-height: 23px;
              overflow: hidden;
              padding: 0.5em 1em;
              white-space: nowrap;
              width: 100%;

              &:first-child {
                border-left: none;
              }

              .ant-checkbox-inner {
                &::after {
                  border-color: #FFF;
                }
              }
            }
          }
        }
      }

      .benchmark-grid-footer {
        display: flex;
        gap: 2em;
        justify-content: flex-end;
        margin: 30px 0;
        width: 100%;

        .benchmark-grid-download {
          background: #134176;
          border-color: #134176;

          &:disabled {
            background: #DDD;
            border-color: #DDD;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}