.segmentationfilter-number-tip {
  color: #AAA;
  font-size: 0.9em;
  font-style: italic;
}

.segmentationfilter-number-range {
  display: flex;
  gap: 1em;
  width: 100%;
}