.popover__organization {
  padding: 10px 40px 22px 12px;
}
.popover__organization .organization__action {
  margin-top: 25px;
}
.popover__organization .radio__groups {
  display: flex;
}
.popover__organization .radio__group:first-child {
  margin-right: 72px;
}
.popover__organization .radio__group .radio__title {
  margin-bottom: 14px;
  font-size: 18px;
  line-height: 23px;
}
.popover__organization .radio__group .ant-radio-group {
  max-height: 200px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.popover__organization .radio__group .ant-radio-group .ant-radio-wrapper {
  margin-bottom: 5px;
}
.popover__organization .radio__group .ant-radio-group .ant-radio-checked .ant-radio-inner {
  background: #1E3968;
  border-color: #1E3968;
}
.popover__organization .radio__group .ant-radio-group .ant-radio-checked .ant-radio-inner::after {
  content: "";
  background-color: #fff;
}

.popover__group {
  padding: 10px 40px 22px 12px;
}
.popover__group .group__block {
  display: flex;
}
.popover__group .group__radio--item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 18px;
  margin-bottom: 18px;
  border-bottom: 1px solid #D8D8D8;
}
.popover__group .group__item:first-child {
  border-right: 1px solid #D8D8D8;
}
.popover__group .group__item:last-child {
  padding-left: 50px;
}
.popover__group .group__item .group__title {
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 23px;
}
.popover__group .group__item .group__icons {
  display: flex;
  align-items: center;
}
.popover__group .group__item .group__checkboxes {
  max-height: 300px;
  overflow-y: auto;
  width: 300px;
}
.popover__group .group__item .ant-radio-group {
  display: flex;
  flex-direction: column;
}
.popover__group .group__item .ant-radio-group .ant-radio-wrapper {
  display: flex;
}
.popover__group .group__item .ant-radio-group .ant-radio-wrapper > span:not(.ant-radio) {
  display: block;
  width: 100%;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.popover__group .group__item .ant-radio-group .ant-radio-wrapper span {
  font-weight: 500;
  line-height: 18px;
}
.popover__group .group__item .ant-radio-group .ant-radio-wrapper span svg {
  color: #1E3968;
}
.popover__group .group__item .ant-radio-group .anticon-edit {
  width: 16px;
  height: 16px;
  transition: 0.4s all;
}
.popover__group .group__item .ant-radio-group .anticon-edit svg {
  width: 16px;
  height: 16px;
  fill: #1E3968;
}
.popover__group .group__item .ant-radio-group .anticon-edit:hover {
  opacity: 0.4;
}
.popover__group .group__item .ant-radio-group .ant-radio-checked .ant-radio-inner {
  background: #1E3968;
  border-color: #1E3968;
}
.popover__group .group__item .ant-radio-group .ant-radio-checked .ant-radio-inner::after {
  content: "";
  background-color: #fff;
}
.popover__group .group__item .delete-group {
  position: relative;
  width: 14px;
  height: 14px;
  margin-left: 10px;
  transition: 0.4s all;
}
.popover__group .group__item .delete-group:hover {
  opacity: 0.4;
}
.popover__group .group__item .delete-group:after {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  transform: rotate(45deg);
  border: 1px solid #C82922;
  background: #C82922;
  top: 6px;
}
.popover__group .group__item .delete-group:before {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  transform: rotate(-45deg);
  border: 1px solid #C82922;
  background: #C82922;
  top: 6px;
}

.group__action {
  background: transparent;
  border: 1px solid #21416F;
  border-radius: 24px;
  color: #21416F;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  margin: 50px auto 0;
  outline: none;
  padding: 10px 30px;
  transition: 0.3s all;
}
.group__action:hover {
  background: #21416F;
  color: #fff;
}

.apply__button {
  background: #21416F;
  color: #fff;
}
.apply__button:hover {
  opacity: 0.5;
}

.popover__content {
  width: 200px;
}
.popover__content .popover__title {
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 10px;
}
.popover__content .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.popover__content .ant-checkbox-wrapper span {
  font-size: 14px;
  line-height: 37px;
}
.popover__content .ant-checkbox-group-item {
  margin-right: 0;
}
.popover__content .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.popover__content .ant-checkbox-checked .ant-checkbox-inner {
  background: #134176;
  border: 1px solid #134176;
  border-radius: 3px;
}

.popover__individual--group .group__block {
  display: flex;
}
.popover__individual--group .group__item {
  min-width: 300px;
}
.popover__individual--group .group__item:first-child {
  border-right: 1px solid #D8D8D8;
}
.popover__individual--group .group__item:last-child {
  padding-left: 50px;
}
.popover__individual--group .group__item .group__title {
  color: #000;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 4px;
}
.popover__individual--group .group__item .group__subtitle {
  color: #000000;
  font-size: 12px;
  font-style: italic;
  line-height: 18px;
  margin-bottom: 14px;
}
.popover__individual--group .group__item .group__checkboxes {
  max-height: 300px;
  overflow-y: auto;
}
.popover__individual--group .group__item .group__checkboxes .ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.popover__individual--group .group__item .group__checkboxes .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
.popover__individual--group .group__item .group__checkboxes .ant-checkbox-wrapper {
  align-items: center;
  color: #000;
  display: flex;
  font-size: 16px;
  line-height: 30px;
}
.popover__individual--group .group__item .group__checkboxes .ant-checkbox-wrapper span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.popover__individual--group .group__item .group__checkboxes .ant-checkbox-wrapper .ant-checkbox {
  overflow: visible;
}
.popover__individual--group .group__item .group__checkboxes .ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #21416F;
  border-color: #21416F;
}
.popover__individual--group .group__item .action__button {
  background: #21416F;
  border: 1px solid #21416F;
  border-radius: 24px;
  color: #fff;
  display: flex;
  font-size: 14px;
  line-height: 18px;
  margin: 25px auto 0;
  outline: none;
  padding: 10px 30px;
  transition: 0.3s all;
}
.popover__individual--group .group__item .action__button:hover {
  opacity: 0.5;
}

.label {
  font-size: 12px;
  line-height: 23px;
  margin: 0;
}

.individual_organisation_filters {
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-height: 300px;
  overflow-y: auto;
  padding-right: 10px;
}

.individual_organisation_filters_group_delete {
  cursor: pointer;
  height: 14px;
  margin-right: 10px;
  position: relative;
  transition: 0.4s all;
  width: 14px;
}
.individual_organisation_filters_group_delete:after {
  background: #C82922;
  border: 1px solid #C82922;
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  transform: rotate(45deg);
  width: 100%;
}
.individual_organisation_filters_group_delete:before {
  background: #C82922;
  border: 1px solid #C82922;
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  transform: rotate(-45deg);
  width: 100%;
}
.individual_organisation_filters_group_delete:hover {
  opacity: 0.4;
}

.individual_organisation_filters_group p {
  margin-bottom: 0;
}
.individual_organisation_filters_group .individual_organisation_filters_block {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.individual_organisation_filters_group .individual_organisation_filters_block .ant-input-number {
  width: 100% !important;
}
.individual_organisation_filters_group .ant-select {
  width: 100%;
}
.individual_organisation_filters_group .ant-select .ant-select-selector {
  border: 2px solid #A0A7B7;
  border-radius: 4px;
  color: #134176;
  height: 100%;
  padding: 6px 34px 7px 10px;
}

.ant-select {
  color: #6c757d;
  width: 85%;
}

.individual_organisation_filter {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-top: 10px;
}
.individual_organisation_filter .individual_organisation_filter_add {
  align-items: center;
  background: #21416F;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 18px;
  height: 22px;
  justify-content: center;
  margin-right: 8px;
  width: 22px;
}
.individual_organisation_filter .individual_organisation_filter_title {
  color: #21416F;
  font-size: 14px;
  line-height: 18px;
}

.individual_organisation_filter_popover {
  max-height: 350px;
  overflow: auto;
}
.individual_organisation_filter_popover .individual_organisation_filter_popover_title {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 6px;
}
.individual_organisation_filter_popover .individual_organisation_filter_popover_list {
  list-style: none;
  margin-bottom: 24px;
  padding: 0;
}
.individual_organisation_filter_popover .individual_organisation_filter_popover_list li {
  color: #21416F;
  cursor: pointer;
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 5px;
  transition: 0.3s all;
}
.individual_organisation_filter_popover .individual_organisation_filter_popover_list li:hover {
  opacity: 0.4;
}