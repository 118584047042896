.topbar__menu {
  max-height: 400px;
  min-width: 400px;
  overflow-y: scroll;
}

.ant-dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
}

.menu__item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  p {
    margin: 0;
  }
}

.menu__info {
  cursor: pointer;
  display: inline-block;
  padding-right: 10px;
  transition: .3s all;

  svg {
    transition: .3s all;
  }

  &:hover {
    svg {
      font-size: 18px;
    }
  }
}