.topbar__menu {
  max-height: 400px;
  min-width: 400px;
  overflow-y: scroll;
}

.ant-dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
}

.menu__item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.menu__item p {
  margin: 0;
}

.menu__info {
  cursor: pointer;
  display: inline-block;
  padding-right: 10px;
  transition: 0.3s all;
}
.menu__info svg {
  transition: 0.3s all;
}
.menu__info:hover svg {
  font-size: 18px;
}