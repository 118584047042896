.listinfo-item {
  align-items: center;
  border: 1px solid #D8D8D8;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: .5em 26px;
  transition: .3s all;
  width: 100%;
  
  &:hover {
    background: #eeeeb3;
  }

  .listinfo-title {
    color: #1E3968;
    font-size: 22px;
    line-height: 39px;
    margin: 0;
  }

  .listinfo-actions {
    align-items: center;
    display: flex;

    .listinfo-button {
      background: #fff;
      border: 1px solid #21416F;
      border-radius: 21px;
      color: #21416F;
      font-size: 13px;
      line-height: 18px;
      opacity: 1;
      padding: .25em 1em;
      transition: .3s all;
    
      &:hover {
        opacity: .4;
      }
    }
  }
}