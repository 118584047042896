.mdg-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .mdg-title {
    font-size: 1.2rem; 
    width: 100%;
  }

  .mdg-content {
    margin-top: 2rem;
    width: 100%;

    .ant-tabs {
      .ant-tabs-content-holder {
        .ant-tabs-content {
          .ant-tabs-tabpane {
            .mdg-tab {
              .mdg-tab-header {
                align-items: center;
                display: flex;
                justify-content: space-between;
                width: 100%;
        
                .mdg-tab-title {
                  font-weight: 500;
                }
        
                .mdg-tab-value {
        
                }
              }

              .mdg-tab-content {
                width: 100%;

                .ant-table-tbody > tr > td,
                .ant-table-thead > tr > th {
                  padding-bottom: .5em !important;
                  padding-top: .5em !important;

                  p {
                    margin: 0 !important;
                  }
                }
              }
            }
          } 
        }
      }  
    }
  }
}