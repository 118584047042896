.levelFilter-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: 1em;
  width: 100%;

  .levelFilter-sub-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .levelFilter-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
  
      .levelFilter-type {
        color: #AAA;
        font-size: .9em;
        font-style: italic;
      }
  
      .levelFilter-subheader {
        display: flex;
        gap: 1em;
  
        .levelFilter-clear {
          color: #FF2277;
          cursor: pointer;
          font-size: .9em;
          font-style: italic;
        }
  
        .levelFilter-clear:hover {
          color: #FF0033;
        }
      }
    }

    .levelFilter-input {
      display: flex;
      flex-direction: column;
      gap: .5em;
      width: 100%;
  
      input::placeholder {
        font-size: 1em !important;
      }
  
      .ant-input-number {
        width: 100%;
      }
    
      .ant-select {
        width: 100%;
      }
    }
  }
}